.MuiTableCell-root{border-bottom: none}
*:focus {
  outline: none !important;
}
@media screen and (min-width: 0px) and (max-width: 600px) {
    .modalSizeAcceptDecline { 
        width:75%;
        
 
     }   /* hide it elsewhere */
   }
   
@media screen and (min-width: 600px) and (max-width: 1200px) {
    .modalSizeAcceptDecline { 
        width:40%;
        
 
     }   /* hide it elsewhere */
   }
 
 
   @media screen and (min-width: 900px) and (max-width: 1500px) {
     .modalSizeAcceptDecline { 
         width:25%;
         
  
      }   /* hide it elsewhere */
    }


    @media screen and (min-width: 1500px) and (max-width: 6000px) {
        .modalSizeAcceptDecline { 
            width:20%;
            
     
         }   /* hide it elsewhere */
       }