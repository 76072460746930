@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap);
@import url(https://fonts.googleapis.com/css2?family=MedievalSharp&display=swap);
@import url(https://fonts.googleapis.com/css?family=Droid+Sans+Mono);
.MuiTableCell-root{border-bottom: none}


@media screen and (min-width: 0px) and (max-width: 600px) {
    .modalSizeAcceptDecline { 
        width:75%;
        
 
     }   /* hide it elsewhere */
   }
   
@media screen and (min-width: 600px) and (max-width: 1200px) {
    .modalSizeAcceptDecline { 
        width:40%;
        
 
     }   /* hide it elsewhere */
   }
 
 
   @media screen and (min-width: 900px) and (max-width: 1500px) {
     .modalSizeAcceptDecline { 
         width:25%;
         
  
      }   /* hide it elsewhere */
    }


    @media screen and (min-width: 1500px) and (max-width: 6000px) {
        .modalSizeAcceptDecline { 
            width:20%;
            
     
         }   /* hide it elsewhere */
       }
.MuiTableCell-root{border-bottom: none}

@media screen and (min-width: 0px) and (max-width: 600px) {
    .modalSizeAcceptDecline { 
        width:75%;
        
 
     }   /* hide it elsewhere */
   }
   
@media screen and (min-width: 600px) and (max-width: 1200px) {
    .modalSizeAcceptDecline { 
        width:40%;
        
 
     }   /* hide it elsewhere */
   }
 
 
   @media screen and (min-width: 900px) and (max-width: 1500px) {
     .modalSizeAcceptDecline { 
         width:25%;
         
  
      }   /* hide it elsewhere */
    }


    @media screen and (min-width: 1500px) and (max-width: 6000px) {
        .modalSizeAcceptDecline { 
            width:20%;
            
     
         }   /* hide it elsewhere */
       }
.MuiTableCell-root{border-bottom: none}


@media screen and (min-width: 0px) and (max-width: 600px) {
    .modalSizeAcceptDecline { 
        width:75%;
        
 
     }   /* hide it elsewhere */
   }
   
@media screen and (min-width: 600px) and (max-width: 1200px) {
    .modalSizeAcceptDecline { 
        width:40%;
        
 
     }   /* hide it elsewhere */
   }
 
 
   @media screen and (min-width: 900px) and (max-width: 1500px) {
     .modalSizeAcceptDecline { 
         width:25%;
         
  
      }   /* hide it elsewhere */
    }


    @media screen and (min-width: 1500px) and (max-width: 6000px) {
        .modalSizeAcceptDecline { 
            width:20%;
            
     
         }   /* hide it elsewhere */
       }
.MuiTableCell-root{border-bottom: none}
*:focus {
  outline: none !important;
}
@media screen and (min-width: 0px) and (max-width: 600px) {
    .modalSizeAcceptDecline { 
        width:75%;
        
 
     }   /* hide it elsewhere */
   }
   
@media screen and (min-width: 600px) and (max-width: 1200px) {
    .modalSizeAcceptDecline { 
        width:40%;
        
 
     }   /* hide it elsewhere */
   }
 
 
   @media screen and (min-width: 900px) and (max-width: 1500px) {
     .modalSizeAcceptDecline { 
         width:25%;
         
  
      }   /* hide it elsewhere */
    }


    @media screen and (min-width: 1500px) and (max-width: 6000px) {
        .modalSizeAcceptDecline { 
            width:20%;
            
     
         }   /* hide it elsewhere */
       }
body {
    background-color: white;
  }
  
  .chat {
    text-align: center;
  }
  
  .chat header {
    background-color: black;
    height: 90px;
    min-height: 50px;
    color: white;
    position: fixed;
    width: 100%;
    top: 82px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 99;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .chat section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
  }
  
  .chat main {
    padding: 10px;
    height: 32vh;
    max-height: 700px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  main::-webkit-scrollbar {
    width: 20px;
  }
  
  main::-webkit-scrollbar-track {
    background: white;
  }
  
  main::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 10px;

  }
 

  .chat form {
    bottom: 0;
    border: #00000036 4px solid;
    border-radius: 10px;
    height: 100px;
    display: flex;
    font-size: 1.5rem;
    z-index: 100;
  }
  
  .chat form button {
    background-color: black;
  }
  
  
  .chat input {
    line-height: 1.5;
    width: 100%;
    font-size: 1.5rem;
    color: BLACK;
    outline: none;
    border: none;
    padding: 0 10px;
  }
  
  .chat button {
    background-color: #282c34; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    font-size: 1.25rem;
  }
  
  .chat button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  
  .chat .sign-in {
    color: #282c34;
    background: white;
    max-width: 400px;
    margin: 0 auto;
  }
  
  
  
  .profileName{
    color: black;
    font-size: 1rem;
    padding: 10px;
  }
  .chat ul, .chat li {
    text-align: left;
    list-style: none;
  }
  
  .chat p {
    max-width: 500px;
    margin-bottom: 12px;
    line-height: 24px;
    padding: 10px 20px;
    border-radius: 25px;
    position: relative;
    color: white;
    text-align: center;
    overflow-wrap: anywhere;
  }
  
  .chat .message {
    display: flex;
    align-items: center;
  }
  .chat .message .profile{
    display: flex;
    flex-flow: column;
    padding: 10px;
    justify-content: right;
    text-align: right;
    align-items: end;
  }
  
  .chat .sent .profile{

    justify-content: right;
    text-align: right;
    align-items: end;
  }
  
  .chat .received .profile{

    justify-content: left;
    text-align: left;
    align-items: start;
  }
  
  .chat .message .timestamp{
    color: lightgray;
  }
  .chat .sent {
    flex-direction: row-reverse;
  }
  
  .chat .sent p {
    color: white;
    background: #fca311;
    /* align-self: flex-end; */
  }
  .chat .received p {
    background: #e5e5ea;
    color: black;
  }
  
  .chat img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 2px 5px;
  }
  
  @media screen and (min-width: 0px) and (max-width: 480px) {
    .chat form {
      font-size: 1rem;
      height: 50px;
    }
    .chat input {
      font-size: 1rem;
      line-height: 1;

    }
    .chat button {
      padding: 7.5px 16px;
      font-size: 1rem;
    }
     
  main::-webkit-scrollbar {
    width: 10px;
  }
      
  .profileName{
    font-size: 0.6rem;
  }
  .chat main {
    font-size: 0.75rem;

  }
  .chat p {
    max-width: 500px;
    margin-bottom: 12px;
    line-height: 24px;
    padding: 5px 10px;
    border-radius: 25px;
    font-size: 0.75rem;
    overflow-wrap: anywhere;
  }
  .chat .received {
    flex-direction: row-reverse;
  }
  .chat .received .profile{

    justify-content: right;
    text-align: right;
    align-items: end;
  }
  
  
  }

  
  .WarLeaderboard {
    max-width: 35rem;
    margin: 5rem auto;
    border-radius: 1rem;
    box-shadow: 2px 2px 16px 1px #aaa;
    font-family: sans-serif;

    
    
  
  }

  @media screen and (max-width: 400px) {
    .WarLeaderboard {
      max-width: 100vw;
 
    }
  }

  
  



  .h1Tag {
    text-align: center;
  }

  
  .warTable {
    
    background-color: #16181e;
    color: #999;
    width: 100%;
    border-collapse: collapse;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    overflow: hidden;

  }

  
  
  
  thead {
    font-size: 0.7rem;
    color: white;
    text-transform: uppercase;
  }

  
  
  tbody .nick {
    font-weight: bold;
    font-size: 0.9rem;
  }
  
  tbody tr:nth-child(2n) {
    background-color: #1f232c;
  }
  
  .nick {
    width: 25ch;
    text-align: center;
  }
  
  .rank{
    text-align: center;
  }
  
  .rank {
    width: 4ch;
    padding-left: 1rem;
  }
  
  .sp{
    width: 20ch;
    text-align: center;

  }

  .kd {
    width: 20ch;
    text-align: center;
  }
  
  th {
    
    padding: 1.5rem 1rem 0.5rem;
  }
  
  td {
    padding: 1rem 1rem;
  }
  


@media screen and (max-width: 500px) {
    .rank{
      font-size: 7px;
      color:aliceblue;
      width: 2ch;
  }
  .nick {
    font-size: 7px;
    color:aliceblue;
    width: 2ch;
    
  }
  tbody .nick {
    color: white;
    font-weight: bold;
    font-size: 0.4rem;
  }
  
  .sp {
    font-size: 7px;
    color:aliceblue
  }
  .kd {
    font-size: 7px;
    color:aliceblue
  }

  .h1Tag{
    font-size:25px
  }

}


@media screen and (max-width: 320px) {
  .rank{
    font-size:5.8px;
    color:aliceblue;
    width: 0ch;
}
.nick {
  font-size: 5.8px;
  color:aliceblue;
  width: 2ch;
  
}
tbody .nick {
  color: white;
  font-weight: bold;
  font-size: 0.4rem;
}

.sp {
  font-size: 5.8px;
  color:aliceblue
}
.kd {
  font-size: 5.8px;
  color:aliceblue
}

.h1Tag{
  font-size:20px
}

}
@media screen and (min-width: 0px) and (max-width: 1200px) {
   .modalSize { 
       width:100%;
       

    }   /* hide it elsewhere */
  }


  @media screen and (min-width: 1150px) and (max-width: 6000px) {
    .modalSize { 
        width:60%;
        
 
     }   /* hide it elsewhere */
   }


   .expand{
    padding-left: 25px !important;
    font-size:12.5px !important;
    /* color:#fca311; */
    /* -webkit-text-stroke: 1px black; */
  }

.card{
  border: 1.3px solid;
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.2);
}


@font-face {
  font-family: knight;
  src: url(/static/media/carta-magna-line.ffp.4c4c45e9.ttf);
}

@font-face {
  font-family: cartoon;
  src: url(/static/media/GROBOLD.c1707969.ttf);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family:  'cartoon',sans-serif;
  font-weight: 100;
      
    letter-spacing: 2px;
}

.charityLogo{
  height: 200px;
}
.noMargin{
  margin: 0px !important;
}


.strikethrough{
  position: relative;
}

.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 5px solid;
  border-color: red;
  
  -webkit-transform:rotate(-30deg);
  transform:rotate(-30deg);
}

.comingSoon{
  color: red;
}

body {
  font-size:100%,;

}

.timer-container{
}
.timer{
  display: flex;
  flex: column 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #fff !important;
  letter-spacing: 1px;
}

.timer div:first-child{
  text-align: center;
}

.timer-icon {
  font-size: 8em;
}

h2 {
  font-weight: 600;
  margin-bottom: 0.5em;
}

.timer div:first-child p {
  color: #f8f8f8;
}

.timer div:first-child{
  border: 1px solid #fff;
  border-radius: 3px;
  display: grid;
  grid-template-columns:repeat(7,60px);
  padding: 16px;
  text-align:center;

}

section p:first-child, .timer div:last-child span{
  /* font-size: 40px; */
}

@media screen and (max-width: 480px){
  .timer div:last-child{
    margin-left: 2em;
    margin-right: 2em;
    padding: 3em 1em;
  }
}
* {
  box-sizing: border-box;
}

body {
  margin: 0;
}



.flipClock {
  display: flex;
  justify-content: space-between;
  width: 550px;

  
}

.flipUnitContainer {
  display: block;
  position: relative;
  width: 120px;
  height: 110px;
  -webkit-perspective-origin: 50% 50%;
          perspective-origin: 50% 50%;
  -webkit-perspective: 300px;
          perspective: 300px;
  background-color: black;
  border-radius: 3px;
  box-shadow: 0px 10px 10px -10px black;
  margin-bottom: 100px;
  
}

.upperCard, .lowerCard {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 50%;
  overflow: hidden;
  border: 1px solid black;
}
.upperCard span, .lowerCard span {
  font-size: 4em;
  font-family:  'cartoon',sans-serif;
  font-weight: lighter;
  color: white;
}

.upperCard {
  align-items: flex-end;
  border-bottom: 0.5px solid black;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.upperCard span {
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}

.lowerCard {
  align-items: flex-start;
  border-top: 0.5px solid black;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.lowerCard span {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.flipCard {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.flipCard span {
  font-family:  'cartoon',sans-serif;
  font-size: 4em;
  font-weight: lighter;
  color: white;
}
.flipCard.unfold {
  top: 50%;
  align-items: flex-start;
  -webkit-transform-origin: 50% 0%;
          transform-origin: 50% 0%;
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
  background-color: black;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border: 0.5px solid black;
  border-top: 0.5px solid black;
}
.flipCard.unfold span {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.flipCard.fold {
  top: 0%;
  align-items: flex-end;
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transform: rotateX(0deg);
          transform: rotateX(0deg);
  background-color: black;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border: 0.5px solid black;
  border-bottom: 0.5px solid black;
}
.flipCard.fold span {
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}

.fold {
  -webkit-animation: fold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
          animation: fold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.unfold {
  -webkit-animation: unfold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
          animation: unfold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

@-webkit-keyframes fold {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
  100% {
    -webkit-transform: rotateX(-180deg);
            transform: rotateX(-180deg);
  }
}

@keyframes fold {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
  100% {
    -webkit-transform: rotateX(-180deg);
            transform: rotateX(-180deg);
  }
}
@-webkit-keyframes unfold {
  0% {
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
}
@keyframes unfold {
  0% {
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
}

.typeOfTime{
  color: white;
  align-items: center;
  text-align: center;
  padding-top: 10px;
  font-size: 20px;
}
@media screen and (max-width: 560px) {
  .flipClock {
    width: 300px;
    margin-left: 20px;
    /* justify-content: center; */
    /* text-align: center; */
    /* align-items: center; */
  }

  .flipUnitContainer {
    width: 65.5px;
    height: 60px;
  }

  .upperCard span, .lowerCard span {
    font-size: 2em;
    
  }

  .flipCard span {
    font-size: 2em;
  }
  .typeOfTime{
    font-size: 12px;
  }
}

@media screen and (max-width: 330px) {
  .flipClock {
    width: 280px;
    /* margin-left: 20px; */
    /* justify-content: center; */
    /* text-align: center; */
    /* align-items: center; */
  }
}




.loading {
  padding-right:12px
}
.loading:after {
  content: '.';
  -webkit-animation: dots 2s steps(5, end) infinite;
          animation: dots 2s steps(5, end) infinite;}

@-webkit-keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: black;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 black,
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 black,
      .5em 0 0 black;}}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: black;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 black,
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 black,
      .5em 0 0 black;}}

      
/*  LEADERBOARD TABLE */

.mountToolTip{
  max-width: 70vw;
}
