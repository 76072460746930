body {
    background-color: white;
  }
  
  .chat {
    text-align: center;
  }
  
  .chat header {
    background-color: black;
    height: 90px;
    min-height: 50px;
    color: white;
    position: fixed;
    width: 100%;
    top: 82px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 99;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .chat section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
  }
  
  .chat main {
    padding: 10px;
    height: 32vh;
    max-height: 700px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  main::-webkit-scrollbar {
    width: 20px;
  }
  
  main::-webkit-scrollbar-track {
    background: white;
  }
  
  main::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 10px;

  }
 

  .chat form {
    bottom: 0;
    border: #00000036 4px solid;
    border-radius: 10px;
    height: 100px;
    display: flex;
    font-size: 1.5rem;
    z-index: 100;
  }
  
  .chat form button {
    background-color: black;
  }
  
  
  .chat input {
    line-height: 1.5;
    width: 100%;
    font-size: 1.5rem;
    color: BLACK;
    outline: none;
    border: none;
    padding: 0 10px;
  }
  
  .chat button {
    background-color: #282c34; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    font-size: 1.25rem;
  }
  
  .chat button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  
  .chat .sign-in {
    color: #282c34;
    background: white;
    max-width: 400px;
    margin: 0 auto;
  }
  
  
  
  .profileName{
    color: black;
    font-size: 1rem;
    padding: 10px;
  }
  .chat ul, .chat li {
    text-align: left;
    list-style: none;
  }
  
  .chat p {
    max-width: 500px;
    margin-bottom: 12px;
    line-height: 24px;
    padding: 10px 20px;
    border-radius: 25px;
    position: relative;
    color: white;
    text-align: center;
    overflow-wrap: anywhere;
  }
  
  .chat .message {
    display: flex;
    align-items: center;
  }
  .chat .message .profile{
    display: flex;
    flex-flow: column;
    padding: 10px;
    justify-content: right;
    text-align: right;
    align-items: end;
  }
  
  .chat .sent .profile{

    justify-content: right;
    text-align: right;
    align-items: end;
  }
  
  .chat .received .profile{

    justify-content: left;
    text-align: left;
    align-items: start;
  }
  
  .chat .message .timestamp{
    color: lightgray;
  }
  .chat .sent {
    flex-direction: row-reverse;
  }
  
  .chat .sent p {
    color: white;
    background: #fca311;
    /* align-self: flex-end; */
  }
  .chat .received p {
    background: #e5e5ea;
    color: black;
  }
  
  .chat img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 2px 5px;
  }
  
  @media screen and (min-width: 0px) and (max-width: 480px) {
    .chat form {
      font-size: 1rem;
      height: 50px;
    }
    .chat input {
      font-size: 1rem;
      line-height: 1;

    }
    .chat button {
      padding: 7.5px 16px;
      font-size: 1rem;
    }
     
  main::-webkit-scrollbar {
    width: 10px;
  }
      
  .profileName{
    font-size: 0.6rem;
  }
  .chat main {
    font-size: 0.75rem;

  }
  .chat p {
    max-width: 500px;
    margin-bottom: 12px;
    line-height: 24px;
    padding: 5px 10px;
    border-radius: 25px;
    font-size: 0.75rem;
    overflow-wrap: anywhere;
  }
  .chat .received {
    flex-direction: row-reverse;
  }
  .chat .received .profile{

    justify-content: right;
    text-align: right;
    align-items: end;
  }
  
  
  }