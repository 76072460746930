
  
  .WarLeaderboard {
    max-width: 35rem;
    margin: 5rem auto;
    border-radius: 1rem;
    box-shadow: 2px 2px 16px 1px #aaa;
    font-family: sans-serif;

    
    
  
  }

  @media screen and (max-width: 400px) {
    .WarLeaderboard {
      max-width: 100vw;
 
    }
  }

  
  



  .h1Tag {
    text-align: center;
  }

  
  .warTable {
    
    background-color: #16181e;
    color: #999;
    width: 100%;
    border-collapse: collapse;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    overflow: hidden;

  }

  
  
  
  thead {
    font-size: 0.7rem;
    color: white;
    text-transform: uppercase;
  }

  
  
  tbody .nick {
    font-weight: bold;
    font-size: 0.9rem;
  }
  
  tbody tr:nth-child(2n) {
    background-color: #1f232c;
  }
  
  .nick {
    width: 25ch;
    text-align: center;
  }
  
  .rank{
    text-align: center;
  }
  
  .rank {
    width: 4ch;
    padding-left: 1rem;
  }
  
  .sp{
    width: 20ch;
    text-align: center;

  }

  .kd {
    width: 20ch;
    text-align: center;
  }
  
  th {
    
    padding: 1.5rem 1rem 0.5rem;
  }
  
  td {
    padding: 1rem 1rem;
  }
  


@media screen and (max-width: 500px) {
    .rank{
      font-size: 7px;
      color:aliceblue;
      width: 2ch;
  }
  .nick {
    font-size: 7px;
    color:aliceblue;
    width: 2ch;
    
  }
  tbody .nick {
    color: white;
    font-weight: bold;
    font-size: 0.4rem;
  }
  
  .sp {
    font-size: 7px;
    color:aliceblue
  }
  .kd {
    font-size: 7px;
    color:aliceblue
  }

  .h1Tag{
    font-size:25px
  }

}


@media screen and (max-width: 320px) {
  .rank{
    font-size:5.8px;
    color:aliceblue;
    width: 0ch;
}
.nick {
  font-size: 5.8px;
  color:aliceblue;
  width: 2ch;
  
}
tbody .nick {
  color: white;
  font-weight: bold;
  font-size: 0.4rem;
}

.sp {
  font-size: 5.8px;
  color:aliceblue
}
.kd {
  font-size: 5.8px;
  color:aliceblue
}

.h1Tag{
  font-size:20px
}

}