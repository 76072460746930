
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

@import url('https://fonts.googleapis.com/css2?family=MedievalSharp&display=swap');


@font-face {
  font-family: knight;
  src: url(./font/carta-magna-line.ffp.ttf);
}

@font-face {
  font-family: cartoon;
  src: url(./font/GROBOLD.ttf);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family:  'cartoon',sans-serif;
  font-weight: 100;
      
    letter-spacing: 2px;
}

.charityLogo{
  height: 200px;
}
.noMargin{
  margin: 0px !important;
}


.strikethrough{
  position: relative;
}

.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 5px solid;
  border-color: red;
  
  -webkit-transform:rotate(-30deg);
  -moz-transform:rotate(-30deg);
  -ms-transform:rotate(-30deg);
  -o-transform:rotate(-30deg);
  transform:rotate(-30deg);
}

.comingSoon{
  color: red;
}

body {
  font-size:100%,;

}

.timer-container{
}
.timer{
  display: flex;
  flex: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #fff !important;
  letter-spacing: 1px;
}

.timer div:first-child{
  text-align: center;
}

.timer-icon {
  font-size: 8em;
}

h2 {
  font-weight: 600;
  margin-bottom: 0.5em;
}

.timer div:first-child p {
  color: #f8f8f8;
}

.timer div:first-child{
  border: 1px solid #fff;
  border-radius: 3px;
  display: grid;
  grid-template-columns:repeat(7,60px);
  padding: 16px;
  text-align:center;

}

section p:first-child, .timer div:last-child span{
  /* font-size: 40px; */
}

@media screen and (max-width: 480px){
  .timer div:last-child{
    margin-left: 2em;
    margin-right: 2em;
    padding: 3em 1em;
  }
}

@import url("https://fonts.googleapis.com/css?family=Droid+Sans+Mono");
* {
  box-sizing: border-box;
}

body {
  margin: 0;
}



.flipClock {
  display: flex;
  justify-content: space-between;
  width: 550px;

  
}

.flipUnitContainer {
  display: block;
  position: relative;
  width: 120px;
  height: 110px;
  perspective-origin: 50% 50%;
  perspective: 300px;
  background-color: black;
  border-radius: 3px;
  box-shadow: 0px 10px 10px -10px black;
  margin-bottom: 100px;
  
}

.upperCard, .lowerCard {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 50%;
  overflow: hidden;
  border: 1px solid black;
}
.upperCard span, .lowerCard span {
  font-size: 4em;
  font-family:  'cartoon',sans-serif;
  font-weight: lighter;
  color: white;
}

.upperCard {
  align-items: flex-end;
  border-bottom: 0.5px solid black;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.upperCard span {
  transform: translateY(50%);
}

.lowerCard {
  align-items: flex-start;
  border-top: 0.5px solid black;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.lowerCard span {
  transform: translateY(-50%);
}

.flipCard {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.flipCard span {
  font-family:  'cartoon',sans-serif;
  font-size: 4em;
  font-weight: lighter;
  color: white;
}
.flipCard.unfold {
  top: 50%;
  align-items: flex-start;
  transform-origin: 50% 0%;
  transform: rotateX(180deg);
  background-color: black;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border: 0.5px solid black;
  border-top: 0.5px solid black;
}
.flipCard.unfold span {
  transform: translateY(-50%);
}
.flipCard.fold {
  top: 0%;
  align-items: flex-end;
  transform-origin: 50% 100%;
  transform: rotateX(0deg);
  background-color: black;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border: 0.5px solid black;
  border-bottom: 0.5px solid black;
}
.flipCard.fold span {
  transform: translateY(50%);
}

.fold {
  -webkit-animation: fold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
          animation: fold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
  transform-style: preserve-3d;
}

.unfold {
  -webkit-animation: unfold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
          animation: unfold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
  transform-style: preserve-3d;
}

@-webkit-keyframes fold {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(-180deg);
  }
}

@keyframes fold {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(-180deg);
  }
}
@-webkit-keyframes unfold {
  0% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}
@keyframes unfold {
  0% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}

.typeOfTime{
  color: white;
  align-items: center;
  text-align: center;
  padding-top: 10px;
  font-size: 20px;
}
@media screen and (max-width: 560px) {
  .flipClock {
    width: 300px;
    margin-left: 20px;
    /* justify-content: center; */
    /* text-align: center; */
    /* align-items: center; */
  }

  .flipUnitContainer {
    width: 65.5px;
    height: 60px;
  }

  .upperCard span, .lowerCard span {
    font-size: 2em;
    
  }

  .flipCard span {
    font-size: 2em;
  }
  .typeOfTime{
    font-size: 12px;
  }
}

@media screen and (max-width: 330px) {
  .flipClock {
    width: 280px;
    /* margin-left: 20px; */
    /* justify-content: center; */
    /* text-align: center; */
    /* align-items: center; */
  }
}




.loading {
  padding-right:12px
}
.loading:after {
  content: '.';
  animation: dots 2s steps(5, end) infinite;}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: black;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 black,
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 black,
      .5em 0 0 black;}}

      
/*  LEADERBOARD TABLE */

.mountToolTip{
  max-width: 70vw;
}