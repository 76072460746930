@media screen and (min-width: 0px) and (max-width: 1200px) {
   .modalSize { 
       width:100%;
       

    }   /* hide it elsewhere */
  }


  @media screen and (min-width: 1150px) and (max-width: 6000px) {
    .modalSize { 
        width:60%;
        
 
     }   /* hide it elsewhere */
   }


   .expand{
    padding-left: 25px !important;
    font-size:12.5px !important;
    /* color:#fca311; */
    /* -webkit-text-stroke: 1px black; */
  }

.card{
  border: 1.3px solid;
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.2);
}
